import React from "react";
import './settings.css'
import NavBar from "../navbar/navbar";


function Settings () {

    return (
        <>
            <NavBar />
            <h1>kikousettings</h1>
        </>
    )
}

export default Settings;